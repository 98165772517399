import { REFRESH_TOKEN_EXPIRY_STORAGE_KEY } from '../constants';

export default function shouldRefreshToken() {
  // Get refresh token from current session and check if it expires in the next 7 days
  const refreshTokenExp = Number(localStorage.getItem(REFRESH_TOKEN_EXPIRY_STORAGE_KEY));

  if (!refreshTokenExp) {
    return false;
  }

  const oneHourFromNow = Date.now() + 1000 * 60 * 60;

  return refreshTokenExp < oneHourFromNow;
}
