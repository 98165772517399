import { ReactElement } from 'react';

import { faCircleCheck, faMoonStars } from '@fortawesome/pro-duotone-svg-icons';

import { ButtonProps } from 'lib/common/components/Button/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { AuthScreenType } from '../AuthScreen';
import PlanetRingedAndStarIcon from '../assets/PlanetRingedAndStarIcon';
import { AUTH_ERROR_HELP_ITEMS, DEVICE_FORGOTTEN_HELP_ITEMS, SIGNED_OUT_HELP_ITEMS } from './helpItems';

import styles from '../auth-screen.module.scss';

export const AUTH_SCREEN_CONFIG = (
  forgetDevice,
  isMediumScreen,
  config
): {
  [key in AuthScreenType]: {
    withHeader?: boolean;
    heading: string;
    subHeading?: string;
    subtitle?: ReactElement | string;
    helpItems: any;
    sideIcon: ReactElement;
    buttons?: (ButtonProps<unknown> & { label: string })[];
  };
} => ({
  AUTH_ERROR: {
    withHeader: true,
    heading: 'Houston, we have a problem.',
    subHeading: 'Here are a few things you can try:',
    helpItems: AUTH_ERROR_HELP_ITEMS(config),
    sideIcon: <PlanetRingedAndStarIcon />
  },
  DEVICE_FORGOTTEN: {
    heading: 'Your Device Has Been Forgotten',
    subtitle: 'For your security, this device will no longer be remembered.',
    helpItems: DEVICE_FORGOTTEN_HELP_ITEMS,
    sideIcon: (
      <Icon
        className={styles['auth-screen__image']}
        icon={faCircleCheck}
        color="primary"
        size={isMediumScreen ? 100 : 150}
      />
    ),
    buttons: [
      {
        label: 'Sign Back In',
        onClick: () => {
          window.location.reload();
        }
      }
    ]
  },
  SIGNED_OUT: {
    heading: 'We’ve Signed You Out',
    subtitle: (
      <>
        Your status has been set to <Text.Bold>Offline</Text.Bold> and we’ve signed you out of Amazon Connect. We
        recommend you always sign out when you finish for the day.
      </>
    ),
    helpItems: SIGNED_OUT_HELP_ITEMS({ forgetDevice, signIn: () => window.location.reload() }),
    sideIcon: (
      <Icon
        className={styles['auth-screen__image']}
        icon={faMoonStars}
        color="primary"
        size={isMediumScreen ? 100 : 150}
      />
    )
  }
});
